import React, { useState, useEffect } from 'react';
import classes from './tableExport.css';
import { string, shape, arrayOf, object } from 'prop-types';
import { CSVLink } from 'react-csv';
import Download from 'feather/download';
import Tooltip from 'src/util/tooltip';
import moment from 'moment';
const lodashClonedeep = require('lodash/cloneDeep');

function TableExport(props) {
    const { rows, headers } = props;

    const [filteredHeaders, setFilteredHeaders] = useState(headers);
    const [translatedData, setTranslatedData] = useState([]);

    useEffect(() => {
        let filtered = headers
            .filter(header => !header.static)
            .map(header => {
                return {
                    key: header.value,
                    label: header.label,
                    ...header
                };
            });

        setFilteredHeaders(filtered);
    }, [headers]);

    function getFileDate() {
        return moment()
            .format('MM-DD-YY')
            .toString();
    }

    function getTranslatedRows() {
        let newRows = lodashClonedeep(rows);
        filteredHeaders.forEach(header => {
            if (header.format) {
                newRows.forEach(row => {
                    if (row[header.value] !== null)
                        row[header.value] = header.format(row[header.value]);
                });
            }
        });
        return newRows;
    }

    function confirmTranslate() {
        function translate() {
            let translatedRows = getTranslatedRows();
            setTranslatedData(translatedRows);
        }
        // if this will take a while, show a confirmation dialogue
        if (rows.length > 3000) {
            if (
                window.confirm(
                    'It may take a while to download this table data. Continue?'
                )
            ) {
                translate();
            }
        } else {
            translate();
        }
    }

    return rows && rows.length ? (
        <div className={classes.root}>
            <Tooltip tooltip={'Download'} hideArrow>
                <CSVLink
                    onClick={() => confirmTranslate()}
                    data={translatedData}
                    headers={filteredHeaders}
                    className={classes.download}
                    filename={`${getFileDate()}_CommissionsTable.csv`}
                >
                    <div className={classes.icon}>
                        <Download />
                    </div>
                </CSVLink>
            </Tooltip>
        </div>
    ) : null;
}

TableExport.propTypes = {
    rows: arrayOf(object),
    headers: arrayOf(
        shape({
            label: string,
            value: string
        })
    ).isRequired
};

export default TableExport;
