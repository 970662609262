import React, { Fragment, useState } from 'react';
import classes from './home.css';
import DateRange from 'src/components/DateRange/';
import TableWrapper from 'src/components/TableWrapper';
import { TableController } from 'fronds/Components';
import PageHeader from 'src/components/PageHeader';
import TableExport from 'src/components/TableExport';
import {
    bool,
    string,
    shape,
    number,
    oneOfType,
    arrayOf,
    object,
    any,
    instanceOf
} from 'prop-types';
import Tooltip from 'src/util/tooltip';
import QueryParams from 'src/util/urlParams/queryParams';
import { resultsPerPageDropdownOptions } from 'src/constants/resultsPerPageOptions';
import invoiceDateSearchOptions from 'src/constants/invoiceDateSearchOptions';
import Splash from 'src/components/Splash';
import NoInvoices from 'src/icons/noinvoices.svg';
import DropdownSelectWrapper from 'src/components/DropdownSelectWrapper';
import { BackendPaginationWrapper } from 'src/components/PaginationWrapper';
const isEqual = require('lodash/isEqual');
import store from 'src/store';
import { useDispatch } from 'react-redux';
import { shareReportMessage } from 'src/components/Dialogue/messages.js';
import appActions from 'src/store/actions/app';
import Share2 from 'feather/share-2';
import { findInvoice } from 'src/store/actions/tableAsyncActions';
import Search from 'feather/search';
import X from 'feather/x';

function HomePage(props) {
    let { queryParams, tableRows, orderedHeaders, InvoiceHeaders } = props;
    const [invoiceSearch, setInvoiceSearch] = useState('');
    const [orderNumberSearch, setOrderNumberSearch] = useState('');
    const [showSearches, setShowSearches] = useState(false);
    let dispatch = useDispatch();
    const { invoiceLetterMap } = store.getState().app;

    function handleSort(sort, asc) {
        queryParams
            .setSortParams(sort, asc)
            .setPaginationParams(1)
            .setURLParams();
    }

    function handleColumnSearch(search, header) {
        queryParams
            .setColumnSearchParam(header, search, orderedHeaders)
            .setColumnComparatorParams(header, orderedHeaders)
            .setPaginationParams(1)
            .setURLParams();
    }

    function handleDateChange(dateRange) {
        queryParams
            .setDateParams(dateRange)
            .setPaginationParams(1)
            .setRollingDateOption('')
            .setURLParams();
    }

    function handleDateSearchOptionChange(dateSearchOption) {
        queryParams
            .setDateSearchOptionParam(dateSearchOption.value)
            .setPaginationParams(1)
            .setURLParams();
    }

    function handleResultsPerPageChange(resultsPerPage) {
        queryParams
            .setResultsPerPageParam(resultsPerPage.value)
            .setPaginationParams(1)
            .setURLParams();
    }

    function handleHeaderOrder(headers) {
        queryParams.setHeaders(headers).setURLParams();
    }

    const headerProps = {
        handleSort,
        initialSort: queryParams.sort,
        handleColumnSearch,
        initialColumnSearch: queryParams.getColumnSearch(orderedHeaders),
        handleHeaderOrder,
        isDraggable: true
    };

    const onHeaderChange = selectedItems => {
        if (selectedItems.length === 0) return;
        queryParams.setHeaders(selectedItems).setURLParams();
    };

    function saveReport() {
        let message = shareReportMessage();
        dispatch(appActions.setConfirmationDialogue(message));
    }

    const fallback = (
        <Splash
            message={'The current search does not match any invoices'}
            callToAction={'Try a Different Date Range'}
            action={() => document.getElementById('startDate').focus()}
            image={<NoInvoices />}
        />
    );

    function handleInvoiceSearch(e) {
        if (e) {
            e.preventDefault();
        }
        if (invoiceSearch) {
            dispatch(findInvoice(invoiceSearch, null));
        }
    }

    function handleOrderNumberSearch(e) {
        if (e) {
            e.preventDefault();
        }
        if (orderNumberSearch) {
            dispatch(findInvoice(null, orderNumberSearch));
        }
    }

    return (
        <div className={classes.root}>
            <PageHeader text={'Invoices'} />

            {showSearches && (
                <div className={classes.searches}>
                    <form onSubmit={handleInvoiceSearch}>
                        <input
                            autoFocus
                            placeholder={'Search invoice #'}
                            className={classes.search}
                            value={invoiceSearch}
                            onChange={e => setInvoiceSearch(e.target.value)}
                        />
                        {invoiceSearch && (
                            <X
                                className={classes.closeSearchIcon}
                                onClick={() => setInvoiceSearch('')}
                            />
                        )}
                        <div
                            className={classes.executeSearchIcon}
                            onClick={() => handleOrderNumberSearch()}
                        >
                            <Search onClick={() => handleInvoiceSearch()} />
                        </div>
                    </form>
                    <form onSubmit={handleOrderNumberSearch}>
                        <input
                            placeholder={'Search order #'}
                            className={classes.search}
                            value={orderNumberSearch}
                            onChange={e => setOrderNumberSearch(e.target.value)}
                        />
                        {orderNumberSearch && (
                            <X
                                className={classes.closeSearchIcon}
                                onClick={() => setOrderNumberSearch('')}
                            />
                        )}
                        <div
                            className={classes.executeSearchIcon}
                            onClick={() => handleOrderNumberSearch()}
                        >
                            <Search />
                        </div>
                    </form>
                </div>
            )}

            <div className={classes.headerIcons}>
                {!showSearches ? (
                    <div className={classes.searchIcon}>
                        <Tooltip
                            tooltip={'Search for Invoice'}
                            placement={'top'}
                            hideArrow
                        >
                            <div
                                className={classes.searchControlWrapper}
                                onClick={() => setShowSearches(true)}
                            >
                                <Search />
                            </div>
                        </Tooltip>
                    </div>
                ) : (
                    <div className={classes.searchIcon}>
                        <Tooltip
                            tooltip={'Close Search'}
                            placement={'top'}
                            hideArrow
                        >
                            <div
                                className={classes.searchControlWrapper}
                                onClick={() => setShowSearches(false)}
                            >
                                <X />
                            </div>
                        </Tooltip>
                    </div>
                )}
                <TableExport
                    rows={tableRows.filteredRows}
                    headers={orderedHeaders}
                />
                <div className={classes.shareIcon}>
                    <Tooltip tooltip={'Share'} placement={'top'} hideArrow>
                        <Share2 onClick={() => saveReport()} />
                    </Tooltip>
                </div>
            </div>
            <TableController
                inheritedClass={classes.tableController}
                left={
                    <Fragment>
                        <div>
                            <DropdownSelectWrapper
                                listItems={invoiceDateSearchOptions}
                                selectedValues={
                                    invoiceDateSearchOptions.find(
                                        opt =>
                                            opt.value ===
                                            queryParams.dateSearchOption
                                    ) || invoiceDateSearchOptions[0]
                                }
                                onSelectionChange={handleDateSearchOptionChange}
                            />
                            <DateRange
                                currentStartDate={queryParams.startDate}
                                currentEndDate={queryParams.endDate}
                                onFocusNull={handleDateChange}
                            />
                        </div>
                        <DropdownSelectWrapper
                            selectedValues={InvoiceHeaders.filter(
                                baseHeader => {
                                    return orderedHeaders.find(header => {
                                        return isEqual(baseHeader, header);
                                    });
                                }
                            )}
                            listItems={InvoiceHeaders.filter(header => {
                                return !header.static;
                            })}
                            placeholder={'Add headers'}
                            onSelectionChange={onHeaderChange}
                            isMulti={true}
                            isClearable={false}
                            hideSelectedOptions={false}
                            controlShouldRenderValue={false}
                            isOptionDisabled={option =>
                                queryParams.headers.length <= 4 &&
                                queryParams.headers.find(header =>
                                    isEqual(header, option.value)
                                )
                            }
                        />
                    </Fragment>
                }
                right={
                    <Fragment>
                        <DropdownSelectWrapper
                            label={'Page Size'}
                            inheritedClass={'mini'}
                            listItems={resultsPerPageDropdownOptions}
                            selectedValues={
                                resultsPerPageDropdownOptions.find(
                                    opt =>
                                        opt.value === queryParams.resultsPerPage
                                ) || resultsPerPageDropdownOptions[0]
                            }
                            onSelectionChange={handleResultsPerPageChange}
                        />
                        <BackendPaginationWrapper
                            totalRows={
                                tableRows.filteredRows
                                    ? tableRows.filteredRows.length
                                    : 0
                            }
                            handlePagination={page => {
                                queryParams
                                    .setPaginationParams(page)
                                    .setURLParams();
                            }}
                            currentPage={queryParams.pageNumber}
                            pageSize={queryParams.resultsPerPage}
                        />
                    </Fragment>
                }
            />
            <TableWrapper
                rows={tableRows.getPage(1)}
                headers={orderedHeaders}
                headerProps={headerProps}
                fallback={fallback}
                primaryKey={invoiceLetterMap.InvoiceNumber}
                scrollId={queryParams.scrollId}
            />
        </div>
    );
}

const { invoiceLetterMap } = store.getState().app;

HomePage.propTypes = {
    tableRows: any,
    rows: arrayOf(
        shape({
            [invoiceLetterMap.InvoiceNumber]: number.isRequired,
            [invoiceLetterMap.CustomerNumber]: string.isRequired,
            [invoiceLetterMap.SalespersonId]: string.isRequired,
            [invoiceLetterMap.SalespersonBranch]: string,
            [invoiceLetterMap.InvoiceAmount]: number.isRequired,
            [invoiceLetterMap.IsFullyPaid]: bool.isRequired,
            [invoiceLetterMap.GrossProfitAmount]: number.isRequired,
            [invoiceLetterMap.GrossProfitPercent]: oneOfType([
                string.isRequired,
                number.isRequired
            ]),
            [invoiceLetterMap.IsCredit]: bool.isRequired,
            [invoiceLetterMap.InvoiceDateUnix]: number.isRequired,
            [invoiceLetterMap.HasCommission]: bool.isRequired
        }).isRequired
    ),
    InvoiceHeaders: any,
    orderedHeaders: arrayOf(object),
    queryParams: instanceOf(QueryParams)
};

export default HomePage;
